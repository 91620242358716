import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { formatISO, parse } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'om-time-input',
  templateUrl: './om-time-input.component.html',
  styleUrls: ['./om-time-input.component.scss']
})
export class OmTimeInputComponent implements OnInit, OnChanges {

  @Input() control: FormControl = new FormControl();
  @Input() disabled: boolean = false;

  @Input() label: string;
  @Input() icon: string;

  @Input() placeholder = '';
  @Input() format = 'HH:mm';
  @Input() step = 1;
  @Input() disabledHours: () => number[] = () => [];
  @Input() disabledMinutes?: (hour: number) => number[] = () => [];
  @Input() disabledSeconds?: (hour: number, minute: number) => number[] = () => [];

  isRequired$ = new BehaviorSubject<boolean>(false);
  _control = new FormControl();

  constructor() { }

  ngOnInit(): void {
    if (this.control.hasValidator(Validators.required)) {
      this.isRequired$.next(true);
      this._control.setValidators(Validators.required);
    }
    if (this.control.disabled) {
      this._control.disable()
    }
    this._control.setValue(this._parseTime(this.control.value));
    this._control.valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe((value) => {
      const timeString = value ? formatISO(value, { representation: 'time' }) : null;
      this.control.setValue(timeString);
      this.control.markAsDirty();
    });
    this.control.registerOnDisabledChange(isDisabled => {if (isDisabled) this._control.disable(); else this._control.enable()})
    this.control.registerOnChange(value => this._control.setValue(this._parseTime(value), { emitEvent: false }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && !changes.control.firstChange) {
      this.ngOnInit();
    }
  }

  private _parseTime(value: string) {
    if (!value) {
      return null;
    }
    if (value.length > 8) {
      value = value.substring(0, 8); //strip timezome
    }
    const time = parse(value, 'HH:mm:ss', new Date());
    return time;
  }
}
